
import React, {useState} from "react"
import Layout from "../../../components/layout"

import WebappWidgetTable from "../../../components/webapps/widget/table";
import CustomerProfileComponent from "../../../components/negosyonow/components/customerprofile";

const formatTools = require("../../../../lib/formatTools");

const mainEntity = "onscustomer";



const mainFields = [
	{"label":"customerid", "formula":"ONSCUSTOMER.onscustomer_id", "dbfield": "customerid", "type": "hidden", "filtertype": "none"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Last Order", "formula":"max(onsorderform_datetime)", "dbfield": "maxdate", "type": "datetime", "filtertype": "date"},
	{"label":"Earliest Order", "formula":"min(onsorderform_datetime)", "dbfield": "mindate", "type": "datetime", "filtertype": "date"},
	{"label":"Amount", "formula":"sum(onsorderform_totalamount)", "dbfield": "amount", "type": "currency", "filtertype": "currency"},
	{"label":"PCs", "formula":"sum(onsorderform_totalpcs)", "dbfield": "qty", "type": "integer", "filtertype": "integer"},
	{"label":"Transactions", "formula":"count(onsorderform_id)", "dbfield": "transaction", "type": "integer", "filtertype": "integer"},
];


const mainFilterFields = [
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Order Date", "dbfield": "onsorderform_datetime", "type": "datetime", "filtertype": "date"},
	{"label":"Amount", "dbfield": "onsorderform_totalamount", "type": "currency", "filtertype": "currency"},
	{"label":"PCs", "dbfield": "onsorderform_totalpcs", "type": "integer", "filtertype": "integer"},
];


const ReportCustomerProfilePage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("Search Customer Profile");
	const [customersearchstate, setCustomersearchstate] = useState({});
	const [customerid, setCustomerid] = useState(0);

	function customerRowClick(e, rowdata, searchstate)
	{
		setCustomerid(rowdata.customerid);
		setPagetitle("Customer Profile")
		setCustomersearchstate(searchstate);
	}
	const defaultstartdate = formatTools.getDateStr(8-24*180); // ~6months ago

	const mainQueryparams = {
		//tablelist: [],
		joinlist: [{type:"left", table: "ONSORDERFORM", condlist: ["ONSCUSTOMER.onscustomer_id=ONSORDERFORM.onscustomer_id"]}],
		grouping: {fieldlist:["ONSCUSTOMER.onscustomer_id", "onscustomer_name"]},
		condlist: [
			"ONSORDERFORM.onsorderform_datetime>='"+defaultstartdate+"'"
		],
	}

	const tmpsearchstate = customersearchstate.hasOwnProperty("filter")?customersearchstate:{
		"filter":[
			/*
			{
				"display":"Past 6 months",
				//"display":"Order date after '"+formatTools.datestr(defaultstartdate)+"'",
				"field":"onsorderform_datetime", "operation":" >= ","value":defaultstartdate},
			//{"display":"Order date before '7/1/2024'","field":"onsorderform_datetime", "operation":" < ","value":"2024-07-01"}
			*/
		],
		"orderbyfield": "transaction",
		"orderbymode": "desc"

	};

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
				{customerid<1 ?
					<WebappWidgetTable
							title={pagetitle}
							parentlink={{"title":"Reports", "url":"/reports"}}
							token={token}
							entity={mainEntity}
							fieldList={mainFields}
							filterfieldList={mainFilterFields}
							userparam={mainQueryparams}
							initsearchstate={tmpsearchstate}
							showControls={true}
							searchRowClick={customerRowClick}
						/>
				:<>
					<CustomerProfileComponent
							location={location}
							customerid={customerid}
							token={token}
							handleClose={(e)=>{setCustomerid(0)}}
						/>
				</>}
		</Layout>
}


export default ReportCustomerProfilePage;
